import Typography from 'typography';
import Parnassus from 'typography-theme-parnassus';

// Theme overrides
Parnassus.googleFonts[0].styles.push('400');

const typography = new Typography(Parnassus);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
